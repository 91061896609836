import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import SidePanelContentSection from '../../../common2/side-panel/SidePanelContentSection';
import Column from '../../../common/layout/Column';
import Text from '../../../common/Text';
import { formatDate } from '../claimsHelpers';
import TextArea from '../../../common2/TextArea/TextArea';
import Uploader from '../../../common/Uploader';
import ClaimStepClosableMessage from './ClaimStepClosableMessage';
import useStepValidation from './useStepValidation';
import { deadlineValidator } from '../claimValidators';

import './SearchClaimInfoStep.scss';
import RichLocale from '../../../common2/RichLocale/RichLocale';

/**
 * @typedef {Object} Deadline
 * @property {('within'|'early'|'past')} outcome
 * @property {string} firstSubmissionDate
 * @property {number} minimumDaysAfterEvent
 * @property {number} maximumDaysAfterEvent
 * @property {string} eventDate
 * @property {number} daysLeftAfterEvent
 */

function OutsideOfDeadlineStep(props) {
  const { claim, as: Container, onNext, onBack, onChange, ...restProps } = props;
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState(false);

  const { t } = useTranslation();
  const onInputChange = useCallback((value, name) => onChange({ [name]: value }), [onChange]);

  const [errors, onClickNext] = useStepValidation(claim, onNext, deadlineValidator);

  if (!claim.deadlines) {
    console.warn('OutsideOfDeadlineStep should not be rendered when deadlines are not present');
    return null;
  }

  /** @type {Deadline} */
  const deadline = claim.deadlines[claim.type];

  if (!deadline || deadline.outcome === 'within') {
    console.warn('OutsideOfDeadlineStep should not be rendered when deadline is within or missing');
    return null;
  }

  const { message, intent } = deadlineMessages[deadline.outcome] || {};

  return (
    <Container {...restProps} isDisabled={isNextBtnDisabled} onNext={onClickNext} onBack={onBack}>
      <ClaimStepClosableMessage intent={intent}>{t(message)}</ClaimStepClosableMessage>
      <Column spacing="small">
        <SidePanelContentSection heading="First submission date">
          <Text ink>{formatDate(deadline.firstSubmissionDate)}</Text>
        </SidePanelContentSection>
        <SidePanelContentSection heading="CLAIM_DEADLINE_EXPLANATION_HEADING">
          <Text ink>
            <Trans
              i18nKey="CLAIM_DEADLINE_OUTBOUND_INVESTIGATION_EXPLANATION"
              values={{
                minimumDaysAfterEvent: deadline.minimumDaysAfterEvent,
                maximumDaysAfterEvent: deadline.maximumDaysAfterEvent,
                eventDate: formatDate(deadline.eventDate),
                daysLeftAfterEvent: deadline.daysLeftAfterEvent,
              }}
            />
          </Text>
        </SidePanelContentSection>
        <SidePanelContentSection heading="Submit claim anyway">
          <Column spacing="small">
            <RichLocale ink>CLAIM_DEADLINE_SUBMIT_ANYWAY_WARNING</RichLocale>
            <TextArea
              name="outsideOfDeadlineReason"
              label="Reason why"
              value={claim.outsideOfDeadlineReason}
              error={errors.outsideOfDeadlineReason}
              onChange={onInputChange}
              isRequired
            />
            <Uploader
              name="deadlineSupportingDocuments"
              files={claim.deadlineSupportingDocuments}
              label="Supporting documentation (if any)"
              allowedFileTypes={['application/pdf', 'image/png', 'image/jpg', 'image/jpeg']}
              helpText="CLAIMS_ALL_ALLOWED_FILE_TYPES_UPLOAD_HELP_TEXT"
              onChange={onInputChange}
              onBusy={setIsNextBtnDisabled}
            />
          </Column>
        </SidePanelContentSection>
      </Column>
    </Container>
  );
}

const deadlineMessages = {
  early: {
    message: 'CLAIM_DEADLINE_EARLY_MESSAGE',
    intent: 'warning',
  },
  past: {
    message: 'CLAIM_DEADLINE_PAST_MESSAGE',
    intent: 'danger',
  },
};

OutsideOfDeadlineStep.propTypes = {
  as: PropTypes.elementType.isRequired,
  claim: PropTypes.shape({
    type: PropTypes.string.isRequired,
    deadlines: PropTypes.object.isRequired,
  }).isRequired,
};

export default React.memo(OutsideOfDeadlineStep);
